import * as React from "react";
import { graphql } from "gatsby";

import Layout from "./layout";
import Seo from "./seo";

const RedirectPage = ({ pageContext: { link }, data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const [seconds, setSeconds] = React.useState(3);

  const countSeconds = async (sec) => {
    console.log(sec);
    setSeconds(sec);
    if (sec != 0)
      setTimeout(() => {
        countSeconds(sec - 1);
      }, 1000);
    else {
      console.log("Redirect");
      window.location.replace(link);
    }
  };

  React.useEffect(() => {
    if (seconds == 3)
      setTimeout(() => {
        countSeconds(seconds);
      }, 1000);
  }, [seconds]);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title=" 301 Moved Permanently" />
      <h1>301 moved permanently</h1>
      <p>
        <br />
        Weiterleitung auf:{" "}
        <a href={link} target="_blank" title={link}>
          {link}
        </a>{" "}
        in <b>{seconds}</b> Sekunden...
      </p>
    </Layout>
  );
};

export default RedirectPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
